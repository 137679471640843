import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import "./Orders.css";
import { connect } from "react-redux";
import { fetchUserNftsStart } from "../../../store/actions/NftAction";
import { translate, t } from "react-multi-lang";
import NoDataFound from "../../NoDataFound/NoDataFound";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";


const NftList = (props) => {

  useEffect(() => {
    props.dispatch(fetchUserNftsStart());
  }, []);

  return (
    <>
      <div className="order-list-sec">
        <Container>
          <h2>{t("nfts_list")}</h2>
          <Row>
            <Col md={12}>
              <div className="table-wrap">
                <Table responsive="md">
                  <thead className="thead-primary">
                    <tr>
                      <th>{t("nft")}</th>
                      <th>{t("name")}</th>
                      <th>{t("symbol")}</th>
                      <th>{t("amount")}</th>
                      <th>{t("status")}</th>
                    </tr>
                  </thead>
                  {props.userNfts.loading ? (
                    <tbody>
                      {[...Array(2)].map((e, i) => (
                        <tr>
                          <td><Skeleton className="img" /></td>
                          <td><Skeleton width={"100%"} /></td>
                          <td><Skeleton width={"100%"} /></td>
                          <td><Skeleton width={"100%"} /></td>
                          <td><Skeleton width={"100%"} /></td>
                          <td><Skeleton width={"100%"} /></td>
                          <td><Skeleton width={"100%"} /></td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (Object.keys(props.userNfts.data).length > 0 &&
                    props.userNfts.data.response.owned_nfts.length > 0 ? (
                    <tbody>
                      {props.userNfts.data.response.owned_nfts.map(
                        (nft, index) => (
                          <>
                            <tr className="alert" role="alert" key={index}>
                              <td>
                                <div className="order-list-flex">
                                  <div className="orderer-product">
                                    <div
                                      className={`order-list-product`}
                                      key={index}
                                    >
                                      <div
                                        className="img"
                                        style={{
                                          backgroundImage: `url(${nft.nft_image_text})`,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="quantity">{nft.nft_name_text}</td>
                              <td className="quantity">
                                {nft.parallel_text}
                              </td>
                              <td className="quantity">
                                {nft.amount ? nft.amount : 0}
                              </td>
                              <td className="quantity white-space-nowrap">
                                {nft.item_status_option_item_status}
                              </td>
                            </tr>
                          </>
                        )
                      )}
                    </tbody>
                  ) : (
                    <div>
                      <NoDataFound></NoDataFound>
                    </div>
                  ))}
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  userNfts: state.nft.user_nfts,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(NftList));

import { combineReducers } from "redux";

import UserReducer from "./UserReducer";
import ChangePasswordReducer from "./ChangePasswordReducer";
import notifyReducer from "react-redux-notify";
import CardsReducer from "./CardsReducer";
import BankAccountReducer from "./BankAccountReducer";
import KycDocumentReducer from "./KycDocumentReducer";
import WalletReducer from "./WalletReducer";
import TransactionReducer from "./TransactionReducer";
import WithDrawReducer from "./WithDrawReducer";
import PageReducer from "./PageReducer";
import ErrorReducer from "./ErrorReducer";
import ProductOwnerReducer from "./ProductOwnerReducer";
import FollowReducer from "./FollowReducer";
import VerificationDocumentReducer from "./VerificationDocumentReducer";
import BookmarkReducer from "./BookmarkReducer";
import HomeReducer from "./HomeReducer";
import OtherUserReducer from "./OtherUserReducer";
import NotificationReducer from "./NotificationReducer";
import LiveVideoReducer from "./LiveVideoReducer";
import ReferralReducer from "./ReferralReducer";
import ProductsReducer from "./ProductsReducer";
import LookUpReducer from "./LookUpReducer";
import DeliveryAddressReducer from "./DeiveryAddressReducer";
import OrderReducer from "./OrderReducer";
import NftReducer from "./NftReducer";

export default combineReducers({
  users: UserReducer,
  changePassword: ChangePasswordReducer,
  notifications: notifyReducer,
  cards: CardsReducer,
  bankAccount: BankAccountReducer,
  kycDocument: KycDocumentReducer,
  wallet: WalletReducer,
  transaction: TransactionReducer,
  withDraw: WithDrawReducer,
  page: PageReducer,
  errorDetails: ErrorReducer,
  proOwner: ProductOwnerReducer,
  follow: FollowReducer,
  docs: VerificationDocumentReducer,
  bookmark: BookmarkReducer,
  home: HomeReducer,
  otherUser: OtherUserReducer,
  notification: NotificationReducer,
  liveVideo: LiveVideoReducer,
  referral: ReferralReducer,
  userProducts: ProductsReducer,
  lookUp: LookUpReducer,
  deliveryAddress: DeliveryAddressReducer,
  order: OrderReducer,
  nft: NftReducer,
});

import {
  FETCH_USER_NFTS_START,
  FETCH_USER_NFTS_SUCCESS,
  FETCH_USER_NFTS_FAILURE,
  FETCH_LIVE_VIDEOS_NFTS_START,
  FETCH_LIVE_VIDEOS_NFTS_SUCCESS,
  FETCH_LIVE_VIDEOS_NFTS_FAILURE,
  CREATE_NFT_ORDER_BY_WALLET_START,
  CREATE_NFT_ORDER_BY_WALLET_SUCCESS,
  CREATE_NFT_ORDER_BY_WALLET_FAILURE,
  CREATE_NFT_ORDER_BY_STRIPE_START,
  CREATE_NFT_ORDER_BY_STRIPE_SUCCESS,
  CREATE_NFT_ORDER_BY_STRIPE_FAILURE,
} from './ActionConstant'

export function fetchUserNftsStart(data) {
  return {
    type: FETCH_USER_NFTS_START,
    data,
  };
}

export function fetchUserNftsSuccess(data) {
  return {
    type: FETCH_USER_NFTS_SUCCESS,
    data,
  };
}

export function fetchUserNftsFailure(error) {
  return {
    type: FETCH_USER_NFTS_FAILURE,
    error,
  };
}

export function fetchLiveVideosNftsStart(data) {
  return {
      type: FETCH_LIVE_VIDEOS_NFTS_START,
      data,
  };
}

export function fetchLiveVideosNftsSuccess(data) {
  return {
      type: FETCH_LIVE_VIDEOS_NFTS_SUCCESS,
      data,
  };
}

export function fetchLiveVideosNftsFailure(error) {
  return {
      type: FETCH_LIVE_VIDEOS_NFTS_FAILURE,
      error,
  };
}

export function createNftOrderByWalletStart(data) {
  return {
      type: CREATE_NFT_ORDER_BY_WALLET_START,
      data,
  };
}

export function createNftOrderByWalletSuccess(data) {
  return {
      type: CREATE_NFT_ORDER_BY_WALLET_SUCCESS,
      data,
  };
}

export function createNftOrderByWalletFailure(error) {
  return {
      type: CREATE_NFT_ORDER_BY_WALLET_FAILURE,
      error
  };
}

export function createNftOrderByStripeStart(data) {
  return {
      type: CREATE_NFT_ORDER_BY_STRIPE_START,
      data,
  };
}

export function createNftOrderByStripeSuccess(data) {
  return {
      type: CREATE_NFT_ORDER_BY_STRIPE_SUCCESS,
      data,
  };
}

export function createNftOrderByStripeFailure(error) {
  return {
      type: CREATE_NFT_ORDER_BY_STRIPE_FAILURE,
      error,
  };
}
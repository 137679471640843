import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Container,
  Image,
  Media,
  Button,
  Badge,
  Nav,
  Navbar,
  Form,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import configuration from "react-global-configuration";
import VerifiedBadgeNoShadow from "../../Handlers/VerifiedBadgeNoShadow";
// import SideBarIndex from "../SideBar/SideBarIndex";
import io from "socket.io-client";
import { updateNotificationCount } from "../../../store/actions/NotificationAction";
import Alert from "react-bootstrap/Alert";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import CreateContentCreatorModal from "../../helper/CreateContentCreatorModal";
import { fetchUserDetailsStart } from "../../../store/actions/UserAction";
import { homeSearchStart } from "../../../store/actions/HomeAction";

let chatSocket;

const HeaderIndex = (props) => {
  const [chatCount, setChatCount] = useState(0);
  const [bellCount, setBellCount] = useState(0);
  const [openSearchResult, setOpenSearchResult] = useState(false);

  useEffect(() => {
    console.log("Inside");
    let chatSocketUrl = configuration.get("configData.chat_socket_url");
    if (chatSocketUrl === "") {
      console.log("no keys configured");
    }
    if (configuration.get("configData.is_notification_count_enabled") == 1) {
      chatSocketConnect();
    }
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
      props.dispatch(fetchUserDetailsStart());
    }
  }, []);

  const chatSocketConnect = () => {
    // check the socket url is configured
    let chatSocketUrl = configuration.get("configData.chat_socket_url");
    if (chatSocketUrl) {
      chatSocket = io(chatSocketUrl, {
        query:
          `commonid:'user_id_` +
          localStorage.getItem("userId") +
          `',myid:` +
          localStorage.getItem("userId"),
      });
      chatSocket.emit("notification update", {
        commonid: "user_id_" + localStorage.getItem("userId"),
        myid: localStorage.getItem("userId"),
      });
      if (localStorage.getItem("socket") == "true") {
        chatSocket.on("notification", (newData) => {
          console.log(newData);
          setChatCount(newData.chat_notification);
          setBellCount(newData.bell_notification);
        });
      } else {
        console.log(false);
        chatSocket.disconnect();
      }
    }
  };

  const [isVisible, setIsVisible] = useState(false);

  const [createContentCreatorModal, setCreateContentCreatorModal] =
    useState(false);

  const closeCreateContentCreatorModal = () => {
    setCreateContentCreatorModal(false);
  };

  const handleSearch = (event) => {
    setOpenSearchResult(true);
    event.preventDefault();
    props.dispatch(homeSearchStart({ search_key: event.currentTarget.value }));
  };

  useEffect(() => {
    if (!props.homeSearch.loading && props.homeSearch.data.display_search == 0) {
      setOpenSearchResult(false);
    }
  }, [!props.homeSearch.loading]);

  return (
    <>
      {localStorage.getItem("userId") ? (
        <div className="new-navbar">
          <Container fluid>
            <Navbar expand="lg" className=" align-items-center m-0 p-4">
              <Link to="/" className="navbar-brand">
                <Image src={configuration.get("configData.site_logo")} />
              </Link>
              {/* <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                className="hide-tab"
              >
                <Image
                  className="hamburger-menu"
                  src={
                    window.location.origin + "/assets/images/hamburger-menu.png"
                  }
                />
              </Navbar.Toggle> */}
              <div className="new-search-bar">
                <div className="new-search-input">
                  {/* <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Dropdown Button
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <Form>
                    <InputGroup>
                      <Form.Control
                        placeholder="search"
                        type="text"
                        aria-label="Username"
                        name="search_key"
                        id="search_key"
                        aria-describedby="basic-addon1"
                        onChange={(event) => handleSearch(event)}
                      />
                      <InputGroup.Text id="basic-addon1">
                        <Image
                          src={
                            window.location.origin + "/assets/images/search.png"
                          }
                        />
                      </InputGroup.Text>
                    </InputGroup>

                    <div
                      className={`search-dropdown ${!openSearchResult ? "hide" : ""
                        }`}
                    >
                      {props.homeSearch.loading ? 'Searching' :
                        <ul>
                          <>
                            {props.homeSearch.data.live_videos.length > 0 ?
                              props.homeSearch.data.live_videos.map((live_video) => (
                                <li>
                                  <Link to={`/live/${live_video.live_video_unique_id}`}>
                                    <div className="search-dropdown-box">
                                      <div className="search-dropdown-content">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/images/icons/video-1.png"
                                          }
                                        />
                                        <h4>{live_video.title}</h4>
                                      </div>
                                      <div className="search-live-btn">Live</div>
                                    </div>
                                  </Link>
                                </li>
                              )) : null}
                            {props.homeSearch.data.categories.length > 0 ?
                              props.homeSearch.data.categories.map((category) => (
                                <li>
                                  <Link
                                    to={`/?category=${category.unique_id}`}
                                  >
                                    <div className="search-dropdown-box">
                                      <div className="search-dropdown-content">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/images/icons/paper-money.png"
                                          }
                                        />
                                        <h4>{category.name}</h4>
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                              )) : ''}
                            {props.homeSearch.data.sub_categories.length > 0 ?
                              props.homeSearch.data.sub_categories.map((sub_category) => (
                                <li>
                                  <Link to={`/?subCategory=${sub_category.unique_id}`}>
                                    <div className="search-dropdown-box">
                                      <div className="search-dropdown-content">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/images/featured/featured-1.png"
                                          }
                                        />
                                        <h4>{sub_category.name}</h4>
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                              )) : ''}
                            {props.homeSearch.data.users.length > 0 ?
                              props.homeSearch.data.users.map((user) => (
                                <li>
                                  <Link to={`/${user.user_unique_id}`}>
                                    <div className="search-dropdown-box">
                                      <div className="search-dropdown-content">
                                        <Image
                                          src={user.picture ? user.picture :
                                            window.location.origin +
                                            "/assets/images/user.png"
                                          }
                                        />
                                        <h4>{user.name}</h4>
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                              )) : ''}
                          </>
                        </ul>
                      }
                    </div>
                  </Form>
                </div>

                <div className="resp-togle-btn-sec">
                  <Nav className="resp-navbar">
                    {localStorage.getItem("is_content_creator") != 2 ? (
                      <Nav.Link href={"/become-a-seller"}>
                        {t("become_a_seller")}
                      </Nav.Link>
                    ) : null}
                    <Nav.Link
                      href="#"
                      data-drawer-trigger
                      aria-controls="drawer-name"
                      aria-expanded="false"
                      onClick={() => setIsVisible(!isVisible)}
                    >
                      <Image
                        className="resp-profile-img"
                        src={props.profile.data.picture ? props.profile.data.picture :
                          window.location.origin +
                          "/assets/images/icons/user.svg"
                        }
                      />
                      <Image
                        className="hamburger-menu"
                        src={
                          window.location.origin +
                          "/assets/images/hamburger-menu.png"
                        }
                      />
                    </Nav.Link>
                  </Nav>
                </div>
              </div>

              {/* <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end resp-justify-center"
              >
                <Nav className="my-auto align-items-center">
                  {localStorage.getItem("is_content_creator") != 2 ? (
                    <Nav.Link href={"/become-a-seller"}>
                     {t("become_a_seller")}
                    </Nav.Link>
                  ) : null}
                  <Nav.Link
                    href="#"
                    data-drawer-trigger
                    aria-controls="drawer-name"
                    aria-expanded="false"
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    <Image
                      src={
                        window.location.origin + "/assets/images/icons/user.svg"
                      }
                    />
                  </Nav.Link>

                </Nav>
              </Navbar.Collapse> */}
            </Navbar>
          </Container>
        </div>
      ) : (
        <div className="new-navbar">
          <Container fluid>
            <Navbar expand="lg" className=" align-items-center m-0 p-4">
              <Link to="/" className="navbar-brand">
                <Image src={configuration.get("configData.site_logo")} />
              </Link>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                className="hide-tab"
              >
                <Image
                  className="hamburger-menu"
                  src={
                    window.location.origin + "/assets/images/hamburger-menu.png"
                  }
                />
              </Navbar.Toggle>
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end resp-justify-center"
              >
                <Nav className="my-auto align-items-center">
                  <Nav.Link href={"/login"}>{t("login")}</Nav.Link>

                  <Nav.Link href={"/signup"} className="invite-style">
                    {t("signup")}
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Container>
        </div>
      )}
      {isVisible && localStorage.getItem("userId") ? (
        <div className="drawer" id="drawer-name" data-drawer-target>
          <div
            className="drawer__overlay"
            data-drawer-close
            tabindex="-1"
            onClick={() => setIsVisible(!isVisible)}
          ></div>
          <div className="drawer__wrapper">
            <div className="drawer__header">
              <div className="drawer__title">
                <Link to="#" className="l-sidebar__avatar" data-name="Profile">
                  <span className="sidebar-hamburger-user-profile">
                    <Image
                      src={localStorage.getItem("user_picture")}
                      alt={configuration.get("configData.site_name")}
                    />
                  </span>
                  <span onClick={() => setIsVisible(!isVisible)}>
                    {" "}
                    <i className="material-icons add-icon">{t("clear")}</i>
                  </span>
                </Link>
                <div className="pull-left side-user-head">
                  <Link
                    to={"/profile"}
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    <h3 className="g-user-name">
                      {localStorage.getItem("name")} {"  "}
                      {localStorage.getItem("is_verified_badge") == 1 ? (
                        <div className="pl-2">
                          <VerifiedBadgeNoShadow />
                        </div>
                      ) : null}
                    </h3>
                    <span className="user-id">
                      @{localStorage.getItem("username")}
                    </span>
                  </Link>

                  <ul className="list-inline">
                    <Media as="li">
                      <Link to={"/fans"} onClick={() => setIsVisible(false)}>
                        <span className="fans-follow">
                          {localStorage.getItem("total_followers")
                            ? localStorage.getItem("total_followers")
                            : 0}
                        </span>{" "}
                        {t("fans")}
                      </Link>
                    </Media>
                    <Media as="li">
                      <Link
                        to={"/following"}
                        onClick={() => setIsVisible(false)}
                      >
                        <span className="fans-follow">
                          {localStorage.getItem("total_followings")
                            ? localStorage.getItem("total_followings")
                            : 0}
                        </span>{" "}
                        {t("following")}
                      </Link>
                    </Media>
                  </ul>
                </div>

                {/* <div className="pull-right">
                  <span className="m-arrow">
                    <Image
                      src={
                        window.location.origin +
                        "/assets/images/icons/arrow-down.svg"
                      }
                      alt={configuration.get("configData.site_name")}
                    />
                  </span>
                </div> */}
              </div>
              {/* <Button
              className="drawer__close"
              data-drawer-close
              aria-label="Close Drawer"
            ></Button> */}
            </div>
            <div className="drawer__content">
              <div className="right-sidebar-menu-item">
                <Link
                  to={"/profile"}
                  className="sidebar-menus-item"
                  data-name="Profile"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <Image
                    src={
                      window.location.origin +
                      "/assets/images/icons/Profile.png"
                    }
                    alt={configuration.get("configData.site_name")}
                  />{" "}
                  {t("my_profile")}
                </Link>

                {localStorage.getItem("is_content_creator") != 2 ? (
                  <Link
                    to={"/become-a-seller"}
                    className="sidebar-menus-item"
                    data-name="Profile"
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    <Image
                      src={
                        window.location.origin +
                        "/assets/images/icons/referal-friend.svg"
                      }
                      alt={configuration.get("configData.site_name")}
                    />{" "}
                    {t("become_a_content_creator")}
                  </Link>
                ) : null}

                <Link
                  to={"/bookmarks"}
                  className="sidebar-menus-item"
                  data-name="Profile"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <Image
                    src={
                      window.location.origin +
                      "/assets/images/icons/bookmarks.svg"
                    }
                    alt={configuration.get("configData.site_name")}
                  />{" "}
                  {t("bookmarks")}
                </Link>
                <Link
                  to={"/list"}
                  className="sidebar-menus-item"
                  data-name="Profile"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <Image
                    src={
                      window.location.origin + "/assets/images/icons/lists.svg"
                    }
                    alt={configuration.get("configData.site_name")}
                  />{" "}
                  {t("lists")}
                </Link>

                <Link
                  to={"/edit-profile"}
                  className="sidebar-menus-item"
                  data-name="Profile"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <Image
                    src={
                      window.location.origin +
                      "/assets/images/icons/settings.svg"
                    }
                    alt={configuration.get("configData.site_name")}
                  />{" "}
                  {t("settings")}
                </Link>
                {configuration.get("configData.is_referral_enabled") == 1 ? (
                  <Link
                    to={"/referrals"}
                    className="sidebar-menus-item"
                    data-name="Profile"
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    <i className="fas fa-gift"></i> {t("referrals")}
                  </Link>
                ) : (
                  ""
                )}
                <Link
                  to={"/nfts-list"}
                  className="sidebar-menus-item"
                  data-name="ecommerce"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <i className="fas fa-shopping-bag"></i>
                  {t("my_nfts")}
                </Link>
                {localStorage.getItem("is_content_creator") != 2 ? (
                  <>
                    {/* <Link
                    to={"/nfts-list"}
                    className="sidebar-menus-item"
                    data-name="ecommerce"
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    <i className="fas fa-shopping-bag"></i>
                    {t("my_nfts")}
                  </Link> */}
                  </>
                ) : (
                  <>
                    {/* <Link
                      to={"/product-list"}
                      className="sidebar-menus-item"
                      data-name="ecommerce"
                      onClick={() => setIsVisible(!isVisible)}
                    >
                      <i className="fas fa-shopping-bag"></i>
                      {t("products_management")}
                    </Link> */}
                    <Link
                      to={"/go-live"}
                      className="sidebar-menus-item"
                      data-name="go-live"
                      onClick={() => setIsVisible(!isVisible)}
                    >
                      <i className="fas fa-video"></i>
                      {t("go_live")}
                    </Link>
                    <Link
                      to={"/live-videos-history"}
                      className="sidebar-menus-item"
                      data-name="go-live"
                      onClick={() => setIsVisible(!isVisible)}
                    >
                      <i className="fas fa-history"></i>
                      {t("live_history")}
                    </Link>
                  </>
                )}

                <hr className="sidebar-menu-divider" />

                <Link
                  to={"/cards"}
                  className="sidebar-menus-item"
                  data-name="Profile"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <Image
                    src={
                      window.location.origin + "/assets/images/icons/card.svg"
                    }
                    alt="Your Cards"
                  />{" "}
                  {t("your_cards")}{" "}
                  <span className="desc">({t("to_subscribe")})</span>
                </Link>

                <Link
                  to={"/add-bank"}
                  className="sidebar-menus-item"
                  data-name="Profile"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <Image
                    src={
                      window.location.origin + "/assets/images/icons/bank.svg"
                    }
                    alt={configuration.get("configData.site_name")}
                  />{" "}
                  {t("add_bank")} <span className="desc">({t("to_earn")})</span>
                </Link>
                <Link
                  to={"/wallet"}
                  className="sidebar-menus-item"
                  data-name="Wallet"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <Image
                    src={
                      window.location.origin + "/assets/images/icons/wallet.svg"
                    }
                    alt={configuration.get("configData.site_name")}
                  />{" "}
                  {t("wallet")}{" "}
                  <span className="desc">({t("your_earnings")})</span>
                </Link>

                <hr className="sidebar-menu-divider" />

                <Link
                  to={`/page/help`}
                  className="sidebar-menus-item"
                  data-name="Profile"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <Image
                    src={
                      window.location.origin + "/assets/images/icons/help.svg"
                    }
                    alt={configuration.get("configData.site_name")}
                  />{" "}
                  {t("help_and_support")}
                </Link>

                <Link
                  to=""
                  className="sidebar-menus-item"
                  data-name="Profile"
                  onClick={() => setIsVisible(!isVisible)}
                  style={{ display: "none" }}
                >
                  <Image
                    src={
                      window.location.origin + "/assets/images/icons/dark.svg"
                    }
                    alt={configuration.get("configData.site_name")}
                  />{" "}
                  {t("dark_mode")}
                </Link>
                <hr className="sidebar-menu-divider" />
                <Link
                  to={"/logout"}
                  className="sidebar-menus-item"
                  data-name="Profile"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <Image
                    src={
                      window.location.origin + "/assets/images/icons/logout.svg"
                    }
                    alt={configuration.get("configData.site_name")}
                  />{" "}
                  {t("logout")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <CreateContentCreatorModal
        createContentCreatorModal={createContentCreatorModal}
        closeCreateContentCreatorModal={closeCreateContentCreatorModal}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  notifications: state.notification.notifications,
  homeSearch: state.home.homeSearch,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(HeaderIndex));

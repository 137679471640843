import React, { useEffect, useState, useRef } from "react";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Media,
  Image,
  Tab,
  Row,
  Col,
  Nav,
  Form,
  Button,
} from "react-bootstrap";
import {
  fetchLiveVideosNftsStart,
} from "../../store/actions/NftAction";
import { updateAvailabiltyProOwnerStart } from "../../store/actions/ProductOwnerAction";
import configuration from "react-global-configuration";
import LiveNftPaymentModal from "./LiveNftPaymentModal";

const NftsList = (props) => {
  const [activeSec, setActiveSec] = useState("available");

  const [searchKey, setSearchKey] = useState();

  const [nftData, setNftData] = useState({});

  const [paymentModal, setPaymentModal] = useState(false);

  const closePaymentModal = () => {
    setPaymentModal(false);
  };

  const openPaymentModal = (nft) => {
    setNftData(nft)
    setPaymentModal(true);
  };

  const handleChange = (event) => {
    setSearchKey(event.target.value);
    props.dispatch(
      fetchLiveVideosNftsStart({ search_key: event.target.value })
    );
  };

  return (
    <>
      <div className="left-side">
        <div className="video-call-product-list-sec">
          <Tab.Container id="left-tabs-example" defaultActiveKey="available">
            <Row>
              <Col sm={12}>
                <Nav variant="pills">
                  <Nav.Item>
                    <Link className="live-back-button"
                      onClick={() => window.location.assign("/")
                      }
                    >
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/icons/back.svg"
                        }
                        className="svg-clone"
                      />
                    </Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="available"
                      onClick={(event) =>
                        props.setActiveSection(event, "available")
                      }
                    >
                      {t("available")}
                    </Nav.Link>
                  </Nav.Item>

                  {/* <Nav.Item>
                    <Nav.Link
                      eventKey="sold"
                      onClick={(event) =>
                        props.setActiveSection(event, "sold")
                      }
                    >
                      {t("sold")}
                    </Nav.Link>
                  </Nav.Item>

                  {props.liveVideo.data.is_owner === 0 && (
                    <Nav.Item>
                      <Nav.Link
                        eventKey="purchased"
                        onClick={(event) =>
                          props.setActiveSection(event, "purchased")
                        }
                      >
                        {t("purchased")}
                      </Nav.Link>
                    </Nav.Item>
                  )} */}
                </Nav>
              </Col>
              <Col sm={12}>
                {/* {props.liveVideo.data.is_streaming == 1 ?  */}
                <Tab.Content>
                  <Tab.Pane eventKey="available">
                    <div className="product-search-sec">
                      {/* <div className="product-search-area">
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          className="product-search-input"
                          value={searchKey}
                          onChange={(event) => {
                            handleChange(event);
                          }}
                        />
                        <button className="send-button">
                          <i className="fas fa-search"></i>
                        </button>
                      </div> */}
                      {props.liveVideosNfts.loading ?
                      "Loading" :  (
                      <div className="video-product-list-box">
                        {Object.keys(props.liveVideosNfts.data).length > 0 && props.liveVideosNfts.data.nfts.map(
                          (nft, index) => (
                            <div className="video-product-list-card">
                              <div className="video-product-list-left-sec">
                                <h4>{nft.nft_name_text}</h4>
                                <h6>{nft.category_text}</h6>
                                <h4 className="sold-color">{nft.amount}</h4>
                                {/* <p>Set Pre-bid</p> */}
                              </div>
                              {props.liveVideo.data.is_streaming == 1 ?
                                <div className="video-product-list-right-sec">
                                  {props.liveVideo.data.user_id != localStorage.getItem("userId") ?
                                    // props.user.is_online_status === 1 && props.user.is_user_online === 1 ?
                                    <Button
                                      type="button"
                                      onClick={() =>
                                        openPaymentModal(nft)
                                      }
                                    >
                                      {t("buy_now")}
                                    </Button>
                                    // : ''
                                    : ''}
                                </div>
                                : configuration.get('configData.is_offline_products_available') == 1 ?
                                  props.liveVideo.data.user_id != localStorage.getItem("userId") ?
                                    // props.user.is_online_status === 1 && props.user.is_user_online === 1 ?
                                    <Button
                                      type="button"
                                      onClick={() =>
                                        setPaymentModal(true)
                                      }
                                    >
                                      {t("buy_now")}
                                    </Button>
                                    : ''
                                  // : ''
                                  : ''}

                            </div>
                          )
                        )}
                      </div>
                      )}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
                {/* : <p className="mt-3">{t("streaming_not_yet_started")}</p>} */}
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
      {paymentModal && Object.keys(nftData).length > 0 ? (
      <LiveNftPaymentModal
        paymentModal={paymentModal}
        closePaymentModal={closePaymentModal}
        liveVideo={props.liveVideo}
        nftData={nftData}
      />
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  liveVideosNfts: state.nft.liveVideosNfts,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(NftsList));

import {
	FETCH_USER_NFTS_START,
	FETCH_USER_NFTS_SUCCESS,
	FETCH_USER_NFTS_FAILURE,
	FETCH_LIVE_VIDEOS_NFTS_START,
	FETCH_LIVE_VIDEOS_NFTS_SUCCESS,
	FETCH_LIVE_VIDEOS_NFTS_FAILURE,
	CREATE_NFT_ORDER_BY_WALLET_START,
	CREATE_NFT_ORDER_BY_WALLET_SUCCESS,
	CREATE_NFT_ORDER_BY_WALLET_FAILURE,
	CREATE_NFT_ORDER_BY_STRIPE_START,
	CREATE_NFT_ORDER_BY_STRIPE_SUCCESS,
	CREATE_NFT_ORDER_BY_STRIPE_FAILURE,
} from '../actions/ActionConstant'

const initialState = {
	user_nfts: {
		data: {},
		loading: true,
		error: false,
	},
	liveVideosNfts: {
		loading: true,
		error: false,
		data: {},
	},
	createOrderWallet: {
		data: {},
		loading: true,
		error: false,
		loadingButtonContent: null,
		buttonDisable: false,
	},
	createOrderStripe: {
		data: {},
		loading: true,
		error: false,
		loadingButtonContent: null,
		buttonDisable: false,
	},
}

const NftReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_USER_NFTS_START:
			return {
				...state,
				user_nfts: {
					data: {},
					loading: true,
					error: false,
				},
			};
		case FETCH_USER_NFTS_SUCCESS:
			return {
				...state,
				user_nfts: {
					data: action.data,
					loading: false,
					error: false,
				},
			};
		case FETCH_USER_NFTS_FAILURE:
			return {
				...state,
				user_nfts: {
					data: {},
					loading: true,
					error: action.error,
				},
			};
		case FETCH_LIVE_VIDEOS_NFTS_START:
			return {
				...state,
				liveVideosNfts: {
					loading: true,
					error: false,
					data: {},
				},
			};
		case FETCH_LIVE_VIDEOS_NFTS_SUCCESS:
			return {
				...state,
				liveVideosNfts: {
					loading: false,
					error: false,
					data: action.data,
				},
			};
		case FETCH_LIVE_VIDEOS_NFTS_FAILURE:
			return {
				...state,
				liveVideosNfts: {
					data: {},
					loading: false,
					error: action.error,
				},
			};
		case CREATE_NFT_ORDER_BY_WALLET_START:
			return {
				...state,
				createOrderWallet: {
					data: {},
					loading: true,
					error: false,
					loadingButtonContent: "Loading... Please wait",
					buttonDisable: true,
				}
			};
		case CREATE_NFT_ORDER_BY_WALLET_SUCCESS:
			return {
				...state,
				createOrderWallet: {
					data: action.data,
					loading: false,
					error: false,
					loadingButtonContent: null,
					buttonDisable: false,
				}
			};
		case CREATE_NFT_ORDER_BY_WALLET_FAILURE:
			return {
				...state,
				createOrderWallet: {
					data: {},
					loading: true,
					error: action.error,
					loadingButtonContent: null,
					buttonDisable: false,
				}
			};
		case CREATE_NFT_ORDER_BY_STRIPE_START:
			return {
				...state,
				createOrderStripe: {
					data: {},
					loading: true,
					error: false,
					loadingButtonContent: "Loading... Please wait",
					buttonDisable: true,
				}
			};
		case CREATE_NFT_ORDER_BY_STRIPE_SUCCESS:
			return {
				...state,
				createOrderStripe: {
					data: action.data,
					loading: false,
					error: false,
					loadingButtonContent: null,
					buttonDisable: false,
				}
			};
		case CREATE_NFT_ORDER_BY_STRIPE_FAILURE:
			return {
				...state,
				createOrderStripe: {
					data: {},
					loading: true,
					error: action.error,
					loadingButtonContent: null,
					buttonDisable: false,
				}
			};
		default:
			return state;
	}
}

export default NftReducer;
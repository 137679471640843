
import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  FETCH_USER_NFTS_START,
  FETCH_LIVE_VIDEOS_NFTS_START,
  CREATE_NFT_ORDER_BY_WALLET_START,
  CREATE_NFT_ORDER_BY_STRIPE_START,
} from "../actions/ActionConstant";
import { checkLogoutStatus } from "../actions/ErrorAction";
import { createNotification } from "react-redux-notify";
import config from "react-global-configuration";
import io from "socket.io-client";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";

import {
  fetchUserNftsSuccess,
  fetchUserNftsFailure,
  fetchLiveVideosNftsSuccess,
  fetchLiveVideosNftsFailure,
  createNftOrderByWalletSuccess,
  createNftOrderByWalletFailure,
  createNftOrderByStripeSuccess,
  createNftOrderByStripeFailure,
} from "../actions/NftAction";

import {
  fetchLiveVideosProductsStart
} from "../actions/LiveVideoAction";

function* fetchUserNftsAPI() {
  try {
    const response = yield api.postMethod("user_nfts_list");

    if (response.data.success) {
      yield put(fetchUserNftsSuccess(response.data.data));
    } else {
      yield put(fetchUserNftsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchUserNftsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* liveVideosNftsAPI(action) {
  try {
    const response = yield api.postMethod("live_video_nfts_list", action.data);
    if (response.data.success) {
      yield put(fetchLiveVideosNftsSuccess(response.data.data));
    } else {
      yield put(fetchLiveVideosNftsFailure(response.data.error));
    }
  } catch (error) {
    yield put(fetchLiveVideosNftsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    // yield put(createNotification(notificationMessage));
  }
}

function* createNftOrderByWalletAPI(action) {
  try {
    const response = yield api.postMethod("nft_orders_create_by_wallet", action.data);
    if (response.data.success) {
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(createNftOrderByWalletSuccess(response.data.data));
      yield put(fetchLiveVideosProductsStart({
        live_video_id: action.data.live_video_id,
      }));
      let chatSocket;
      let chatSocketUrl = config.get("configData.chat_socket_url");
      chatSocket = io(chatSocketUrl, {
        query: `room: '` + response.data.data.live_video.virtual_id + `'`,
      });

      chatSocket.emit("livevideo buynow", response.data.data.live_video.virtual_id);

    } else {
      yield put(createNftOrderByWalletFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(createNftOrderByWalletFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* createNftOrderByStripeAPI(action) {
  try {
    const response = yield api.postMethod("nft_orders_create_by_stripe", action.data);
    if (response.data.success) {
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(createNftOrderByStripeSuccess(response.data.data));
      yield put(fetchLiveVideosProductsStart({
        live_video_id: action.data.live_video_id,
      }));
      let chatSocket;
      let chatSocketUrl = config.get("configData.chat_socket_url");
      chatSocket = io(chatSocketUrl, {
        query: `room: '` + response.data.data.live_video.virtual_id + `'`,
      });

      chatSocket.emit("livevideo buynow", response.data.data.live_video.virtual_id);
    } else {
      yield put(createNftOrderByStripeFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(createNftOrderByStripeFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_USER_NFTS_START, fetchUserNftsAPI),
    yield takeLatest(CREATE_NFT_ORDER_BY_WALLET_START, createNftOrderByWalletAPI),
    yield takeLatest(FETCH_LIVE_VIDEOS_NFTS_START, liveVideosNftsAPI),
    yield takeLatest(CREATE_NFT_ORDER_BY_STRIPE_START, createNftOrderByStripeAPI),
  ]);
}
